import React, { lazy, Suspense } from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Register from "./Register";

import AuthLayout from "layouts/Auth.js";
import DashboardLayout from "layouts/Page.js";
import { ChakraProvider } from "@chakra-ui/react";
// Custom Chakra theme
import theme from "theme/theme.js";
import { Provider } from 'react-redux';
import { store } from './app/store';
import './i18n';
import Login from "./Login";
import { Login_1_2 } from 'components/Login1.2/Login/login';
import LoginSuccess from "./LoginSuccess";
// import SpeedTest from "./views/Pages/SpeedTest";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import Root from "./Root";
import { TPOPracticePage } from "./pagecomponents/TPOPracticePage";
import { PasswordResetRequestPage } from "./pagecomponents/PasswordResetRequestPage";
import { PasswordResetPage } from "./pagecomponents/PasswordResetPage";
import ShortcutPurchase from "./ShortcutPurchase";
import mixpanel from 'mixpanel-browser';
import PageViewTracker from './components/PageViewTracker'; // Import the PageViewTracker
import ToeflIbt from './views/Pages/ToeflIbt'
import AiChat from 'AiChat';


const SpeedTest = lazy(() => import("./views/Pages/SpeedTest"));
mixpanel.init('3724690ef07434885ea1e0382fbdd5ed', {
    debug: false,
    ignore_dnt: true
});


ReactDOM.render(
    <ChakraProvider theme={theme} resetCss={false} position="relative">
        <Provider store={store}>
            <Router>
                <PageViewTracker />
                <Switch>
                    <Route path="/register">
                        <Login_1_2 />
                    </Route>
                    <Route path="/login" component={Login_1_2} />

                    {/* <Route path="/login" component={Login} /> */}
                    <Route path="/speed_test">
                        <Suspense fallback={<div>Loading...</div>}>
                            <SpeedTest />
                        </Suspense>
                    </Route>
                    <Route path="/reset_password_request">
                        <PasswordResetRequestPage />
                    </Route>
                    <Route path="/reset_password">
                        <PasswordResetPage />
                    </Route>
                    <Route path="/set_password">
                        <PasswordResetPage isSetPassword={true} />
                    </Route>
                    {/* <Route path={`/login`} component={Login} /> */}
                    <Route path={`/login_success`} component={LoginSuccess} />
                    <Route path={`/trial`} component={ShortcutPurchase} />
                    <Route path={`/auth`} component={AuthLayout} />
                    <Route path={`/toefl`} component={DashboardLayout} />
                    <Route path={'/aiChat'} component={DashboardLayout} />
                    <Route path={`/ielts`} component={DashboardLayout} />
                    <AuthenticatedRoute path="/admin" component={DashboardLayout} />
                    <Route path={`/purchase`} component={DashboardLayout} />
                    <Route path={`/ps_assistant`} component={DashboardLayout} />
                    <Redirect from="/personal_statement_assistant" to="/ps_assistant" />
                    <Route path={`/account`} component={DashboardLayout} />
                    <Route from={`/share/:gid`} component={DashboardLayout} />

                    <AuthenticatedRoute path="/mock_test" component={TPOPracticePage} />
                    <Route path="/campus_certification" component={DashboardLayout} />
                    <Route path="/campus_certification_guidance" component={DashboardLayout} />
                    <Route path="/toefl_ibt/:tpo_idx/:sections/:qid" component={ToeflIbt} />
                    <Route path="/">
                        <Root />
                    </Route>
                </Switch>
            </Router>
        </Provider>
    </ChakraProvider>,
    document.getElementById("root")
);
