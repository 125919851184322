import {Box, Button, Divider, Flex, HStack, Icon, Text, VStack} from '@chakra-ui/react';
import React, {useRef, useState} from 'react';
import {FaMicrophone} from 'react-icons/fa';

function ToeflAdjustingTheMicrophoneRecord() {
    const [isRecording, setIsRecording] = useState(false);
    const [volume, setVolume] = useState(0);
    const mediaRecorderRef = useRef(null);
    const audioChunks = useRef([]);

    const ShownIndicatorPart = ({value, color}) => {
        return (
            <Flex justify="space-between" mb={2}>
                {[...Array(6)].map((_, i) => (
                    <Box
                        key={i}
                        w={4}
                        h={10}
                        bg={i < value ? color : "gray.300"}
                        mr={1}
                        borderRadius="2px"
                    />
                ))}
            </Flex>
        );
    };

    const ShownIndicator = ({value, color}) => {
        return (
            <Flex>
                <Flex direction={"column"} alignItems="center" mt={4}>
                    <ShownIndicatorPart value={value} color={color}></ShownIndicatorPart>
                    <Text>Too Quiet</Text>
                </Flex>
                <Divider orientation="vertical" borderColor="black" borderStyle="dashed" borderWidth={1} height="75px"
                         mr={1}/>
                <Flex direction={"column"} alignItems="center" mt={4}>
                    <ShownIndicatorPart value={value - 6} color={color}></ShownIndicatorPart>
                    <Text>Good</Text>
                </Flex>
                <Divider orientation="vertical" borderColor="black" borderStyle="dashed" borderWidth={1} height="75px"
                         mr={1}/>
                <Flex direction={"column"} alignItems="center" mt={4}>
                    <ShownIndicatorPart value={value - 12} color={color}></ShownIndicatorPart>
                    <Text>Too Loud</Text>
                </Flex>
            </Flex>
        );
    };

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({audio: true});
            mediaRecorderRef.current = new MediaRecorder(stream);

            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunks.current.push(event.data);
                }
            };

            mediaRecorderRef.current.onstart = () => {
                setIsRecording(true);
            };

            mediaRecorderRef.current.onstop = () => {
                const audioBlob = new Blob(audioChunks.current, {type: 'audio/wav'});
                const audioUrl = URL.createObjectURL(audioBlob);
                const audio = new Audio(audioUrl);
                audio.play();
                audioChunks.current = [];
                setIsRecording(false);
            };

            mediaRecorderRef.current.start();

            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const source = audioContext.createMediaStreamSource(stream);
            const analyser = audioContext.createAnalyser();
            analyser.fftSize = 2048;
            const bufferLength = analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);
            source.connect(analyser);

            const updateVolume = () => {
                analyser.getByteTimeDomainData(dataArray);
                let sum = 0;
                for (let i = 0; i < bufferLength; i++) {
                    sum += dataArray[i];
                }
                const average = sum / bufferLength;
                setVolume(Math.min(average / 255 * 18, 18)); // 将音量值映射到0-18之间
                if (isRecording) {
                    requestAnimationFrame(updateVolume);
                }
            };

            updateVolume();
        } catch (error) {
            console.error('Microphone access denied:', error);
        }
    };


    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
    };

    const handleRecordClick = () => {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    };

    return (
        <Box maxWidth="860px" margin="auto" padding="4">
            <HStack spacing={8} align="stretch">
                <Button
                    variant="solid"
                    colorScheme="teal"
                    size="lg"
                    borderRadius="full"
                    boxShadow="md"
                    padding="6"
                    width="40"
                    height="40"
                    border="2px solid teal"
                    m={4}
                    onClick={handleRecordClick}
                >
                    <VStack spacing="1">
                        <Icon as={FaMicrophone} boxSize="6"/>
                        <Text>{isRecording ? 'Stop' : 'Record'}</Text>
                    </VStack>
                </Button>

                <VStack spacing={8} align="stretch">
                    <Text>Select the 'Record' button. A timer will count down until the system is ready to
                        record.</Text>
                    <Text>To check your microphone level, you will record the following paragraph using your normal tone
                        and volume.</Text>
                    <Text>There are several reasons why I would prefer to live in a large city. Some of the greatest
                        advantages would include the number of job opportunities and career options, public
                        transportation, greater diversity, and a wealth of entertainment. Also, large cities typically
                        have a great deal to offer in terms of history, art and culture.</Text>
                    <ShownIndicator value={volume} color={"teal.500"}></ShownIndicator>
                </VStack>
            </HStack>
        </Box>
    );
}

export default ToeflAdjustingTheMicrophoneRecord;
