import {Box, Divider, Heading, Icon, Text, VStack} from '@chakra-ui/react';
import React from "react";
import {FaVolumeUp} from 'react-icons/fa';

function ToeflAdjustingTheVolume() {
    return (
        <Box maxWidth="860px" margin="auto" padding="4">
            <VStack spacing={8} align="stretch">
                <Heading as="h1" size="lg" textAlign="left" style={{fontWeight: 'normal'}}>
                    Adjusting the Volume
                </Heading>
                <Divider borderWidth={1}/>
                <Text>
                    To adjust the volume, select the Volume icon at the top of the screen. The volume control will
                    appear. Move the volume indicator to the left or the right to change the volume.
                </Text>
                <Text>
                    To close the volume control, select the Volume icon again.
                </Text>
                <Text>
                    You will be able to change the volume during the test if you need to.
                </Text>
                <Text>
                    <Icon as={FaVolumeUp} color={"teal.500"}
                          fontSize={60}
                          style={{verticalAlign: '-2px'}}
                          mr={4}
                    />
                    You now have the option to adjust the volume.
                </Text>
            </VStack>
        </Box>
    );
}

export default ToeflAdjustingTheVolume;