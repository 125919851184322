import {Box, Divider, Heading, HStack, Icon, Text, VStack} from '@chakra-ui/react';
import React from "react";
import {FaMicrophone,FaHeadphones,FaVolumeUp} from 'react-icons/fa';
function ToeflHeadwareCheck() {
    return (
        <Box maxWidth="860px" margin="auto" padding="4">
            <VStack spacing={8} align="stretch">
                <Heading as="h1" size="lg" textAlign="left" style={{fontWeight: 'normal'}}>
                    Headware Check
                </Heading>
                <Divider borderWidth={1}/>
                <Text>
                    Please make sure your headset is on. Follow the instructions on each screen. Be sure that your
                    microphone is properly positioned and adjusted to allow for the best possible recording. Speak
                    directly into the microphone and in your normal speaking voice.
                </Text>
                <HStack spacing={16}>
                    <Icon as={FaMicrophone}
                          color={"teal.500"}
                          fontSize={100}/>
                    <Icon as={FaHeadphones}
                          color={"teal.500"}
                          fontSize={100}/>
                    <Icon as={FaVolumeUp}
                          color={"teal.500"}
                          fontSize={100}/>
                </HStack>
                <Text>
                    Select <strong>Continue</strong> when you are ready to go on.
                </Text>
            </VStack>
        </Box>
    );
}

export default ToeflHeadwareCheck;