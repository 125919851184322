import {Box, Divider, Flex, Heading, HStack, Text, VStack} from '@chakra-ui/react';
import React, {useEffect, useState} from "react";
import {FaHeadphonesAlt, FaReadme} from "react-icons/fa";
import {FiFileText, FiMic} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import {ToeflIbtContext} from "./ToeflIbtContext";
import {useGetGradingQuery} from "../../services/cmApi";


const TOEFL_READING_SCORE = {
    20: "30",
    19: "29",
    18: "27",
    17: "25",
    16: "24",
    15: "23",
    14: "21",
    13: "20",
    12: "18",
    11: "17",
    10: "15",
    9: "14",
    8: "12",
    7: "10",
    6: "8",
    5: "6",
    4: "4",
    3: "2",
    2: "1",
    1: "0",
    0: "0"
}

const TOEFL_LISTENING_SCORE = {
    28: "30",
    27: "29",
    26: "28",
    25: "27",
    24: "26",
    23: "25",
    22: "24",
    21: "23",
    20: "22",
    19: "21",
    18: "20",
    17: "19",
    16: "18",
    15: "17",
    14: "16",
    13: "15",
    12: "14",
    11: "13",
    10: "12",
    9: "11",
    8: "10",
    7: "9",
    6: "8",
    5: "7",
    4: "6",
    3: "5",
    2: "4",
    1: "2",
    0: "0"
}


function ToeflScoreReport({sections}) {
    const {questionContent, answerContent} = React.useContext(ToeflIbtContext);
    const [scores, setScores] = useState({});
    const {t, i18n} = useTranslation();

    useEffect(() => {
        let totalScore = 0;
        let scoresDic = {};
        sections.forEach(section => {
            const score = Number(getScore({sectionName: section}) || 0);
            scoresDic[section] = score;
            totalScore += score;
        });
        scoresDic['total'] = totalScore;
        setScores(scoresDic);
    }, [sections, answerContent]);


    function getScore({section}) {
        const sectionQuestions = questionContent?.[section]?.tasks;
        if (['reading', 'listening'].includes(section)) {
            const sectionAnswers = answerContent[section];
            let correctCount = 0;
            sectionQuestions?.forEach((task) => {
                task?.questions.forEach((q) => {
                    if (isEqual(sectionAnswers[q.sub_question_idx], q.sub_question_answer)) {
                        correctCount += 1;
                    }
                });
            });
            if (section === 'reading') {
                return TOEFL_READING_SCORE[correctCount];
            } else if (section === 'listening') {
                return TOEFL_LISTENING_SCORE[correctCount];
            }
        } else { // 口语写作计算平均分
            let totalScores = [];
            sectionQuestions?.forEach((task, index) => {
                const answer = answerContent?.['speaking']?.[index + 1];
                if (answer && answer.startsWith('G_')) {
                    // 假设 useGetGradingQuery 已经在组件内部被调用并返回结果
                    let gradingResult = useGetGradingQuery({gid: answer});
                    if (gradingResult.isSuccess && gradingResult.data.data) {
                        const estimatedScore = gradingResult.data.data['estimated_score'];
                        if (estimatedScore !== undefined) {
                            totalScores.push(estimatedScore);
                        }
                    }
                }
            });
            return totalScores.length > 0 ? totalScores.reduce((a, b) => a + b, 0) / totalScores.length : 0;
        }
    }


    function isEqual(answer1, answer2) {
        const preprocess = (value) => {
            if (typeof value === 'string') {
                if (value.length > 1) {
                    return value.split('').sort().join('');
                }
            }
            if (Array.isArray(value)) {
                return value.sort().join("");
            }
            return value;
        };

        const processedAnswer1 = preprocess(answer1);
        const processedAnswer2 = preprocess(answer2);
        return processedAnswer1 === processedAnswer2;
    }


    const ScoreSection = ({section}) => {
        let icon;
        if (section === 'listening') {
            icon = <FaHeadphonesAlt color='green'/>;
        } else if (section === 'reading') {
            icon = <FaReadme color='green'/>;
        } else if (section === 'speaking') {
            icon = <FiMic color='green'/>;
        } else if (section === 'writing') {
            icon = <FiFileText color='green'/>;
        }

        return (
            <VStack>
                <Flex direction={'row'} alignItems={'center'} mt={2}>
                    {icon}
                    <Text fontWeight={'bold'} ml={1}>{t(section)}</Text>
                </Flex>
                <Box
                    borderRadius="xl"
                    bgColor={'teal.100'}
                    width={'130px'}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Text fontSize={'35px'} height={'42px'}>{scores?.[section]}</Text>
                    <Text mb={1} fontSize={'md'}>out of 30</Text>
                </Box>
            </VStack>
        );
    };


    return (
        <Box margin="auto" padding="1" bg={'gray.100'} height={"92vh"} width={'100%'}>
            <Box bg="white" borderRadius="md" p={4} width={'100%'}>
                <Heading size="md" p={2}>Test Date: September 18, 2024</Heading>
                <Divider borderWidth={1}/>
                <HStack spacing={8}>
                    <VStack>
                        <Flex direction={'row'} alignItems={'center'} mt={2}>
                            <Text fontWeight={'bold'} ml={1}>{t('total_score')}</Text>
                        </Flex>
                        <Box
                            borderRadius="xl"
                            bgColor={'teal.100'}
                            width={'130px'}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                        >
                            <Text fontSize={'35px'} height={'42px'}>{scores?.['total']}</Text>
                            <Text mb={1} fontSize={'md'}>out of {30 * sections.length}</Text>
                        </Box>
                    </VStack>
                    {sections.map((section, index) => {
                        return (
                            <ScoreSection section={section}></ScoreSection>
                        );
                    })}
                </HStack>
            </Box>
        </Box>
    );
}

export default ToeflScoreReport;
