import React, {useEffect, useState} from 'react';
// import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Flex,
    Link,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Tab,
    TabList,
    Tabs,
    Text,
    useToast
} from '@chakra-ui/react';
import {FaAngleLeft, FaAngleRight, FaEye, FaEyeSlash, FaVolumeHigh} from "react-icons/fa6";
import {useHistory} from "react-router-dom";
import {getRouteInfo, ToeflIbtContext} from "./ToeflIbtContext";
import FinishWarning from "./ToeflIbtFinishWarning"
import axios from 'axios';
import authHeader from "../../services/auth-header";
import {useTranslation} from "react-i18next";
import mixpanel from 'mixpanel-browser';

const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;


function ToeflIbtMenu({isCountdownPaused, setIsCountdownPaused}) {
    const history = useHistory();
    const {
        questionContent,
        setQuestionContent,
        volume,
        setVolume,
        answerContent,
        setAnswerContent
    } = React.useContext(ToeflIbtContext);
    // qid为all时则是模考，练习时则为单个part
    const {
        parentPath,
        questionSet,
        tpoNum,
        section,
        practiceType,
        qid,
        childPath,
        otherParams,
        answerJson
    } = getRouteInfo(location.pathname);
    const [partIdx, subIdx] = parseParams();
    const totalNum = questionContent?.[section]?.total_question_num || 0;
    const isShowCountDownDisplay = showCountDownDisplay();
    const [countdown, setCountdown] = useState(0); // 初始化倒计时为60秒
    const [showCountdown, setShowCountdown] = useState(true); // 控制倒计时显示的状态
    const [isOpen, setIsOpen] = useState(false); // 添加状态管理
    const [displayReadingTabs, setDisplayReadingTabs] = useState(false); // 添加状态管理
    const toast = useToast();
    const {t, i18n} = useTranslation();


    useEffect(() => { // 获取问题详情
        fetchQuestionContent();
    }, [parentPath]);

    useEffect(() => {
        refreshTotalTime();
        checkDisplayReadingTabs(partIdx, subIdx);
        if (section === "reading" && childPath !== 'directions') {
            setIsCountdownPaused(false)
        }
        if (childPath === "answer") {
            // 触发后台答案保存
            if (answerJson) {
                setAnswerContent(prevAnswerContent => ({
                    ...prevAnswerContent,
                    [section]: answerJson
                }));
            } else {
                Object.entries(answerContent).map(([sectionStr, answerData]) => {
                    if (['reading', 'listening'].includes(sectionStr)) {
                        saveAnswer(sectionStr, answerData);
                    } else {
                        Object.entries(answerData).map(([partIdxV, answerData]) => {
                            const question = questionContent[sectionStr].tasks[partIdxV - 1]
                            handleSubmit(question, answerData, partIdxV);
                        })
                    }
                    return null; // 如果不匹配 sectionStr，返回 null
                })
            }
        }
        if (childPath === 'directions' && partIdx === 0) {
            mixpanel.track('Mock test directions', {
                practiceType: practiceType,
                section: section,
            });
        }
    }, [section, childPath]);


    useEffect(() => {


    }, [otherParams]);

    // useEffect(() => {
    //     setCountdown(totalTime);
    // }, [parentPath, tpoNum, totalTime]);

    useEffect(() => {
        let timer;
        if (!isCountdownPaused) {
            timer = setInterval(() => {
                setCountdown(prev => {
                    if (prev > 0) {
                        return prev - 1;
                    } else if (practiceType !== 'practice' && ['text', 'question'].includes(childPath)) {
                        const nextUrl = getNextUrl();
                        history.push(nextUrl);
                    } else {
                        return 0
                    }
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [isCountdownPaused]);

    const saveAnswer = async (sectionStr, answerData) => {
        if (!answerData || Object.keys(answerData).length === 0) {
            return;
        }
        try {
            let headers = authHeader();
            console.log('questionSet', questionSet)
            const response = await axios.post(API_URL + '/api/save_answer', {
                answer_json: handleAnswerContent(sectionStr),
                practice_type: practiceType,
                question_id: tpoNum,
                question_set: questionSet,
                section: sectionStr,
                test: 'toefl'
            }, {headers: headers});
            toast({
                title: "Answer saved successfully",
                status: "success",
                duration: 2000,
                isClosable: true,
            });
        } catch (error) {
            console.error("Error saving answer:", error);
            toast({
                title: "Failed to save answer",
                description: error.message,
                status: "error",
                duration: 2000,
                isClosable: true,
            });
        }
    };

    const handleSubmit = (question, data, partIdxV) => {
        let headers = authHeader();
        console.log('question:', question)
        console.log('answerData:', data)
        const params = {
            'type': question.gen_type,
            'topic': question.question,
            'audio': data.startsWith('G_') ? '' : data,
            'audioSource': "RECORDER",
            'reading': typeof question.reading_texts === 'string' ? question.reading_texts : (question.reading_texts || []).join(''),
            'listening': typeof question.listening_texts === 'string' ? question.listening_texts : (question.listening_texts || []).join(''),
            'tpo_number': question.tpo_number,
            "qid": question.qid,
            'gid': data.startsWith('G_') ? data : '',
            'structured': '1',
            'authorization': headers['Authorization'],
            'lang': i18n.language
        };
        let url = process.env.REACT_APP_WS_BACKEND_URL ? process.env.REACT_APP_WS_BACKEND_URL : window.location.origin;
        url = url.replace("https://", "wss://").replace("http://", "ws://");
        url = url + "/api/generate_ws";
        const ws = new WebSocket(url);
        ws.onopen = (event) => {
            ws.send(JSON.stringify(params));
        };

        ws.onmessage = function (event) {
            let data = JSON.parse(event.data);
            if (data.t === "metadata") {
                const gid = data.c.gid;
                if (question.section === 'speaking') {
                    setAnswerContent(prevAnswerContent => ({
                        ...prevAnswerContent,
                        speaking: {
                            ...prevAnswerContent.speaking,
                            [partIdxV]: gid
                        }
                    }));
                } else if (question.section === 'writing') {
                    console.log("gid:", gid)
                    setAnswerContent(prevAnswerContent => ({
                        ...prevAnswerContent,
                        writing: {
                            ...prevAnswerContent.writing,
                            [partIdxV]: gid
                        }
                    }));
                }
            }
        };

        ws.onclose = function (event) {
            console.log("WebSocket close");
        };

        ws.onerror = function (error) {
            console.log("WebSocket error: ", error);
        };
    };


    function parseParams() {
        if (!otherParams || otherParams.length === 0) {
            return [0, 0];
        } else if (otherParams.length === 1) {
            return [Number(otherParams[0]), 0];
        } else if (otherParams.length === 2) {
            return [Number(otherParams[0]), Number(otherParams[1])];
        } else {
            return [0, 0];
        }
    }

    function showCountDownDisplay() {
        if (section === "speaking") {
            return false
        } else if (["headware_check", "adjusting_the_volume", "adjusting_the_microphone",
            "adjusting_the_microphone_record", "directions", "answer"].includes(childPath)) {
            return false
        } else if (section === "writing" && ["audio"].includes(childPath)) {
            return false
        }
        return true
    }

    const onClose = () => setIsOpen(false); // 关闭
    const onContinue = () => {
        history.push(`${parentPath}/answer`);
        onClose();
    }; // 继续


    function handleAnswerContent() {
        const answerContentV = {}
        questionContent?.[section]?.tasks.map(task => {
            const qid = task?.qid
            const taskAnswerContent = {}
            task?.questions.map((question, index) => {
                const subQuestionIdx = question?.sub_question_idx
                taskAnswerContent[index + 1] = answerContent[section][subQuestionIdx] || '';
            })
            answerContentV[qid] = taskAnswerContent
        })
        return answerContentV
    }

    const refreshTotalTime = () => {
        const minute = 60;
        // const minute = 1;
        let totalTimeV = 36 * minute;
        if (childPath === "directions" && partIdx === 0) {
            if (qid === "all") {
                totalTimeV = 36 * minute
            } else if (section === "listening") {
                totalTimeV = 7 * minute  // one part
            } else if (section === "reading") {
                totalTimeV = 18 * minute // one part
            }
            setCountdown(totalTimeV)
        } else if (section === "writing") {
            if (partIdx === 1) {
                if (childPath === 'text') {
                    totalTimeV = 3 * minute
                } else if (childPath === 'question') {
                    totalTimeV = 20 * minute  // writing_task1
                }
            } else if (childPath === 'question') {
                totalTimeV = 10 * minute  // writing_task2
            }
            setCountdown(totalTimeV)
        }
    };


    function getNextUrlForReading() {
        if (childPath === "directions") {
            return `${parentPath}/text/1`;
        } else if (childPath === "text") {
            const nextSubIdx = questionContent?.[section]?.tasks[partIdx - 1].questions[0].sub_question_idx;
            return `${parentPath}/question/${partIdx}/${nextSubIdx}`;
        } else if (childPath === "question") {
            const nextPartIdx = partIdx + 1;
            const nextSubIdx = subIdx + 1;
            const questions = questionContent?.[section]?.tasks[partIdx - 1].questions;
            let curPartEndIdx = questions[questions.length - 1].sub_question_idx;
            if (nextSubIdx > totalNum) { // 单科最后一题
                if (practiceType === 'full_mock_test') { // 全科模考跳到下一个section
                    // setIsOpen(true)
                    const newParentPath = parentPath.replace(section, "listening");
                    return `${newParentPath}/directions`;
                } else if (practiceType === 'mock_test' && countdown > 0) {
                    setIsOpen(true)
                } else {
                    return `${parentPath}/answer`
                }
            } else if (Number(subIdx) === Number(curPartEndIdx)) { // 单任务最后一题
                return `${parentPath}/text/${nextPartIdx}`;
            } else {
                return `${parentPath}/question/${partIdx}/${nextSubIdx}`;
            }
        } else if (childPath === "answer") {
            return `/toefl/reading`;
        }
    }

    function getNextUrlForListening() {
        if (childPath === "directions") {
            return `${parentPath}/audio/1`;
        } else if (childPath === "audio") {
            const questions = questionContent?.[section]?.tasks[partIdx - 1].questions;
            const nextSubIdx = questions?.[0].sub_question_idx;
            return `${parentPath}/question/${partIdx}/${nextSubIdx}`;
        } else if (childPath === "re_play") {
            return location.pathname.replace("re_play", "question");
        } else if (childPath === "question") {
            const questions = questionContent?.[section]?.tasks[partIdx - 1].questions;
            const nextPartIdx = partIdx + 1;
            const nextSubIdx = subIdx + 1;
            let curPartEndIdx = questions[questions.length - 1].sub_question_idx;
            if (nextSubIdx > totalNum) { // 单科最后一题
                if (practiceType === 'full_mock_test') { // 全科模考跳到下一个section
                    const newParentPath = parentPath.replace(section, "speaking");
                    return `${newParentPath}/directions`;
                } else if (practiceType === 'mock_test' && countdown > 0) {
                    setIsOpen(true)
                } else {
                    return `${parentPath}/answer`
                }
            } else if (Number(subIdx) === Number(curPartEndIdx)) { // 单任务最后一题
                return `${parentPath}/audio/${nextPartIdx}`;
            } else if (isRePlay(partIdx, nextSubIdx)) { // 重读题目
                return `${parentPath}/re_play/${partIdx}/${nextSubIdx}`;
            } else {
                return `${parentPath}/question/${partIdx}/${nextSubIdx}`;
            }
        } else if (childPath === "answer") {
            return `/toefl/listening`;
        }
    }

    function getNextUrlForSpeaking() {
        if (childPath === "directions") {
            if (!otherParams || otherParams?.length === 0) {
                return `${parentPath}/directions/1`;
            } else if (partIdx === 1) {
                return `${parentPath}/question/${partIdx}`;
            } else if (partIdx === 2 || partIdx === 3) {
                return `${parentPath}/text/${partIdx}`;
            } else if (partIdx === 4) {
                return `${parentPath}/audio/${partIdx}`;
            }
        } else if (childPath === "text") {
            return `${parentPath}/audio/${partIdx}`;
        } else if (childPath === "audio") {
            return `${parentPath}/question/${partIdx}`
        } else if (childPath === "question") {
            console.log('partIdx', partIdx)
            if (qid!=='all'){
                return `${parentPath}/answer`
            }else if ([1, 2, 3].includes(partIdx)) {
                return `${parentPath}/directions/${partIdx + 1}`;
            } else if (partIdx === 4) {
                console.log('partIdx', partIdx)
                if (practiceType === 'full_mock_test') {
                    const newParentPath = parentPath.replace(section, "writing");
                    return `${newParentPath}/directions`;
                } else {
                    return `${parentPath}/answer`
                }
            }
        } else if (childPath === "answer") {
            return `/toefl/speaking`;
        }
    }

    function getNextUrlForWriting() {
        if (childPath === "directions") {
            if (!otherParams || otherParams?.length === 0) {
                return `${parentPath}/directions/1`;
            } else if (partIdx === 1) {
                return `${parentPath}/text/${partIdx}`;
            } else if (partIdx === 2) {
                return `${parentPath}/question/${partIdx}`;
            }
        } else if (childPath === "text") {
            return `${parentPath}/audio/${partIdx}`;
        } else if (childPath === "audio") {
            return `${parentPath}/question/${partIdx}`
        } else if (childPath === "question") {
            if (qid!=='all'){
                return `${parentPath}/answer`
            }else if (partIdx === 1) {
                return `${parentPath}/directions/2`;
            } else {
                return `${parentPath}/answer`
            }
        } else if (childPath === "answer") {
            if (practiceType === 'full_mock_test') {
                return `/toefl/full_mock_test`;
            } else {
                return `/toefl/writing`;
            }
        }
    }

    function getNextUrl() {
        // Pause countdown
        if (section === "listening" || ["directions", "audio"].includes(childPath)) {
            setIsCountdownPaused(true)
        }
        // adjusting
        if (childPath === "headware_check") {
            return `${parentPath}/adjusting_the_volume`
        } else if (childPath === "adjusting_the_volume") {
            if (practiceType === 'full_mock_test' || section === "speaking") {
                // General practice exam or individual oral practice exam
                return `${parentPath}/adjusting_the_microphone`
            } else {
                return `${parentPath}/directions`
            }
        } else if (childPath === "adjusting_the_microphone") {
            return `${parentPath}/adjusting_the_microphone_record`
        } else if (childPath === "adjusting_the_microphone_record") {
            return `${parentPath}/directions` //調試
        }
        // other
        switch (section) {
            case "listening":
                return getNextUrlForListening();
            case "reading":
                return getNextUrlForReading();
            case "writing":
                return getNextUrlForWriting();
            case "speaking":
                return getNextUrlForSpeaking();
            default:
                return null;
        }
    }

    function isRePlay(partIdx, nextSubIdx) {
        const taskObj = questionContent?.[section]?.tasks[partIdx - 1];
        if (taskObj && taskObj?.questions) {
            for (const questionObj of taskObj?.questions) {
                if (questionObj.sub_question_idx.toString() === nextSubIdx.toString()) {
                    if (questionObj.sub_question_audio && questionObj.sub_question_audio.replay_url) {
                        return true;
                    }
                }
            }
        }
        return false
    }

    function checkDisplayReadingTabs(partIdx, SubIdx) {
        setDisplayReadingTabs(false)
        const taskObj = questionContent?.[section]?.tasks[partIdx - 1];
        if (taskObj && taskObj?.questions) {
            for (const questionObj of taskObj?.questions) {
                if (questionObj.sub_question_idx.toString() === SubIdx?.toString()) {
                    const isDisplay = (questionObj.sub_question_texts &&
                        questionObj.sub_question_texts[0].includes("Directions")
                    )
                    setDisplayReadingTabs(isDisplay)
                }
            }
        }
    }


    const formatTime = (seconds) => {
        seconds = seconds < 0 ? 0 : seconds
        const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
        const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
        const secs = String(seconds % 60).padStart(2, '0');
        return `${hours}:${minutes}:${secs}`;
    };


    const fetchQuestionContent = async () => {
        try {
            let param;
            if (qid === "all") {
                param = {
                    test: "toefl",
                    section: section,
                    questionSet: questionSet,
                    tpoNumber: tpoNum
                };
            } else {
                param = {
                    qid: qid,
                    test: "toefl",
                    section: section,
                    questionSet: questionSet,
                    tpoNumber: tpoNum
                };
            }
            axios.post(API_URL + '/api/query_mock_test_question_bank', param)
                .then(response => {
                    if (response.status === 200) {
                        // console.log("response.data.data", response.data.data)
                        // setQuestionContent(response.data.data)
                        setQuestionContent(prevQuestionContent => ({
                            ...prevQuestionContent,
                            [section]: response.data.data
                        }));
                    }
                }).catch(error => {
                console.error('There was an error!', error);
            });
        } catch (error) {
            console.error('Error occurred while obtaining the content of the problem:', error);
        }
    };

    const handleClick = () => {
        history.push(`/toefl/${section}`)
    };

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
        <Flex direction={"column"}>
            <Flex as="nav" align="center" justify="space-between" wrap="wrap" bg="teal.700" color="white">
                <Flex align="center" ml={5} mr={5}>
                    {/*<Text fontSize="4xl" fontWeight="bold">*/}
                    {/*    lingoleap*/}
                    {/*</Text>*/}
                    <div onClick={handleClick}>
                        <Text fontSize="4xl" fontWeight="bold">
                            lingoleap
                        </Text>
                    </div>
                </Flex>

                <Flex>
                    <Popover>
                        <PopoverTrigger>
                            <Button
                                colorScheme="teal"
                                variant="outline"
                                mr={3}
                                color="white"
                                borderColor="white"
                                _hover={{borderColor: "white"}}
                                rightIcon={<FaVolumeHigh fontSize={20}/>}
                            >
                                Volume
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent bg="white" borderColor="gray.200">
                            <PopoverBody>
                                <Box p={1}>
                                    <Flex align="flex-end" justify="space-between" mb={2}>
                                        {[...Array(15)].map((_, i) => (
                                            <Box
                                                key={i}
                                                w={2}
                                                h={`${(i + 8) * 3}px`}
                                                bg={i < volume * 15 ? "teal.500" : "gray.300"}
                                                mr={1}
                                                borderRadius="6px"
                                            />
                                        ))}
                                    </Flex>
                                    <Slider
                                        aria-label="volume-slider"
                                        value={volume * 100}
                                        min={0}
                                        max={100}
                                        onChange={(val) => {
                                            setVolume(val / 100);
                                        }}
                                    >
                                        <SliderTrack bg="gray.300">
                                            <SliderFilledTrack bg="teal.500"/>
                                        </SliderTrack>
                                        <SliderThumb boxSize={4} bg="teal.500"/>
                                    </Slider>
                                </Box>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                    <Button colorScheme="teal"
                            variant="outline"
                            mr={3}
                            color="white"
                            borderColor="white"
                            _hover={{borderColor: "white"}}
                            leftIcon={<FaAngleLeft fontSize={20}/>}
                            onClick={() => {
                                history.goBack();
                            }}>
                        Back
                    </Button>
                    <Button
                        bg="white"
                        variant="outline"
                        mr={3}
                        color="teal"
                        rightIcon={<FaAngleRight fontSize={20}/>}
                        onClick={() => {
                            const nextUrl = getNextUrl();
                            history.push(nextUrl);
                        }}
                    >
                        {childPath === "directions" ? "Continue" : "Next"}
                    </Button>
                </Flex>
            </Flex>
            {(childPath !== "answer") && (
                <Flex direction={"row"} justify="space-between" bg="gray.100">
                    <Flex direction={"row"} align="center" fontSize={"xl"}>
                        <Text p={2} ml={8}> {capitalizeFirstLetter(section)} </Text>
                        {(childPath === 'directions' && partIdx !== 0) || (
                            ["question", "text", "audio"].includes(childPath) && subIdx === 0
                        ) && (
                            <Flex direction={"row"}>
                                <Text p={2}> | </Text>
                                <Text p={2}> Passage {partIdx} of {questionContent?.[section]?.tasks.length} </Text>
                            </Flex>
                        )}
                        {(["question", "text", "re_play"].includes(childPath) && subIdx !== 0) && (
                            <Flex direction={"row"}>
                                <Text p={2}> | </Text>
                                <Text p={2}> Question {subIdx} of {totalNum} </Text>
                            </Flex>
                        )}

                    </Flex>
                    {displayReadingTabs && (
                        <Tabs>
                            <TabList>
                                <Tab onClick={() => {
                                    const url = location.pathname.replace("text", "question");
                                    history.replace(url);
                                }}>View Question</Tab>
                                <Tab onClick={() => {
                                    const url = location.pathname.replace("question", "text");
                                    history.replace(url);
                                }}>View Passage</Tab>
                            </TabList>
                        </Tabs>
                    )}
                    <Flex direction={"row"} align="center" fontSize={"xl"}>
                        {isShowCountDownDisplay && (
                            <Flex align="center" ml={8}>
                                {showCountdown && <Text fontSize="md">{formatTime(countdown)}</Text>}
                                <Button onClick={() => setShowCountdown(prev => !prev)} ml={2}
                                        color="teal" leftIcon={showCountdown ? <FaEyeSlash/> : <FaEye/>}
                                        as={Link} variant="Link"
                                        css={{
                                            color: 'teal',
                                            ':hover': {
                                                color: 'teal', // 保持悬停时的颜色不变
                                                backgroundColor: 'transparent', // 保持背景透明
                                                textDecoration: 'none' // 移除下划线
                                            }
                                        }}
                                >
                                    {showCountdown ? "Hide Time" : "Show Time"}
                                </Button>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            )}
            <FinishWarning isOpen={isOpen} onClose={onClose} onReturn={onClose} onContinue={onContinue}></FinishWarning>
        </Flex>
    );
}

export default ToeflIbtMenu;