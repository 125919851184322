import React, {useEffect, useState} from "react";
import {Box, Flex} from '@chakra-ui/react';
import {Route, Switch, useHistory} from 'react-router-dom';
import {getRouteInfo, ToeflProvider} from '../../components/toeflIbt/ToeflIbtContext';
import ToeflIbtMenu from '../../components/toeflIbt/ToeflIbtMenu';
import ToeflListeningDirections from '../../components/toeflIbt/ToeflListeningDirections';
import ToeflAudio from '../../components/toeflIbt/ToeflAudio';
import ToeflListeningQuestion from '../../components/toeflIbt/ToeflListeningQuestion';
import ToeflListeningAudioRe from "../../components/toeflIbt/ToeflListeningAudioRe";
import ToeflReadingDirections from '../../components/toeflIbt/ToeflReadingDirections';
import ToeflReadingQuestion from '../../components/toeflIbt/ToeflReadingQuestion';
import ToeflReadingText from "../../components/toeflIbt/ToeflReadingText";
import ToeflHeadwareCheck from "../../components/toeflIbt/ToeflHeadwareCheck";
import ToeflAdjustingTheVolume from "../../components/toeflIbt/ToeflAdjustingTheVolume";
import ToeflAdjustingTheMicrophone from "../../components/toeflIbt/ToeflAdjustingTheMicrophone";
import ToeflAdjustingTheMicrophoneRecord from "../../components/toeflIbt/ToeflAdjustingTheMicrophoneRecord";
import ToeflSpeakingDirections from "../../components/toeflIbt/ToeflSpeakingDirections";
import ToeflWritingDirections from "../../components/toeflIbt/ToeflWritingDirections";
import ToeflSpeakingQuestion from "../../components/toeflIbt/ToeflSpeakingQuestion";
import ToeflWritingQuestion from "../../components/toeflIbt/ToeflWritingQuestion";
import ToeflSpeakingText from "../../components/toeflIbt/ToeflSpeakingText";
import ToeflWritingText from "../../components/toeflIbt/ToeflWritingText";
import ToeflAnswer from "../../components/toeflIbt/ToeflAnswer";

function ToeflIbt() {
    let history = useHistory();
    const {parentPath, section, practiceType, childPath} = getRouteInfo();
    const [isCountdownPaused, setIsCountdownPaused] = useState(true);
    useEffect(() => {
        if (!childPath) {
            if (practiceType.includes('mock_test')) { // Jump to 'Headware Check' in mock exam mode
                history.replace(`${parentPath}/headware_check`);
            } else { // Jump to 'directions' in practice mode
                history.replace(`${parentPath}/directions`);
            }
        }
    }, [parentPath, section]);

    return (
        <ToeflProvider>
            <Flex direction="column" height="100vh">
                <ToeflIbtMenu isCountdownPaused={isCountdownPaused} setIsCountdownPaused={setIsCountdownPaused}/>
                <Box flex="1" overflowY="auto">
                    {/*<Box height="25%"></Box>*/}
                    <Switch>
                        <Route path={`${parentPath}/headware_check`} render={() => (<>
                            <Box height="20%"></Box><ToeflHeadwareCheck/></>)}/>
                        <Route path={`${parentPath}/adjusting_the_volume`} render={() => (<>
                            <Box height="20%"></Box><ToeflAdjustingTheVolume/></>)}/>
                        <Route path={`${parentPath}/adjusting_the_microphone`} render={() => (<>
                            <Box height="20%"></Box><ToeflAdjustingTheMicrophone/></>)}/>
                        <Route path={`${parentPath}/adjusting_the_microphone_record`} render={() => (<>
                            <Box height="20%"></Box><ToeflAdjustingTheMicrophoneRecord/></>)}/>
                        <Route path={`${parentPath}/directions/:partIdx?`} render={() => (<>
                            <Box height="20%"></Box>
                            {section.includes('reading') && <ToeflReadingDirections/>}
                            {section.includes('listening') && <ToeflListeningDirections/>}
                            {section.includes('speaking') && <ToeflSpeakingDirections/>}
                            {section.includes('writing') && <ToeflWritingDirections/>}

                        </>)}/>
                        <Route path={`${parentPath}/audio/:partIdx`} render={() => (<>
                            <Box height="10%"></Box><ToeflAudio/>
                        </>)}/>
                        <Route path={`${parentPath}/re_play/:partIdx/:subIdx`} render={() => (<>
                            <Box height="10%"></Box><ToeflListeningAudioRe/></>)}/>
                        <Route path={`${parentPath}/text/:partIdx/`} render={() => (<>
                            {section.includes('reading') && <ToeflReadingText/>}
                            {section.includes('speaking') && <><Box height="10%"></Box><ToeflSpeakingText/></>}
                            {section.includes('writing') &&
                                <ToeflWritingText setIsCountdownPaused={setIsCountdownPaused}/>}</>)}/>
                        <Route path={`${parentPath}/question/:partIdx/:subIdx?`} render={() => (<>
                            {section.includes('listening') && <><Box height="10%"></Box>
                                <ToeflListeningQuestion setIsCountdownPaused={setIsCountdownPaused}/></>}
                            {section.includes('reading') && <ToeflReadingQuestion/>}
                            {section.includes('speaking') && <><Box height="20%"></Box><ToeflSpeakingQuestion/></>}
                            {section.includes('writing') &&
                                <ToeflWritingQuestion setIsCountdownPaused={setIsCountdownPaused}/>}
                        </>)}/>
                        <Route path={`${parentPath}/answer`} render={() => (<>
                            <ToeflAnswer/>
                            {/*{section.includes('listening') && <ToeflListeningAnswer/>}*/}
                            {/*{section.includes('reading') && <ToeflReadingAnswer/>}*/}
                        </>)}/>
                    </Switch>
                </Box>
            </Flex>
        </ToeflProvider>
    );
}

export default ToeflIbt;
