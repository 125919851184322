import React from 'react';
import {Box, Divider, Tab, TabList, Tabs} from '@chakra-ui/react';
import {useTranslation} from "react-i18next";
import ToeflReadingAnswer from './ToeflReadingAnswer'
import ToeflListeningAnswer from './ToeflListeningAnswer'
import ToeflWritingAnswer from './ToeflWritingAnswer'
import ToeflSpeakingAnswer from './ToeflSpeakingAnswer'
import {FaHeadphonesAlt, FaReadme} from "react-icons/fa";
import {FiFileText, FiMic} from "react-icons/fi";
import ToeflScoreReport from "./ToeflScoreReport";


function ToeflAnswer() {
    const sections = getSections()
    console.log(sections)
    const [curSection, setCurSection] = React.useState('scoreReport');
    const {t, i18n} = useTranslation();

    function getSections() {
        const sectionStr = location.pathname.split('/')[3];
        if (sectionStr.includes('all_')) {
            return ['reading', 'listening', 'speaking', 'writing'];
        }
        return ['reading', 'listening', 'writing', 'speaking'].filter(
            (section) => sectionStr.includes(section)
        );
    }


    return (
        <Box margin="auto" bg={'gray.100'} width={'99%'}>
            <Tabs variant='enclosed' bg={'white'} borderRadius={'md'}>
                <TabList alignItems="center" justifyContent="center">
                    <Tab key={-1} onClick={() => setCurSection('scoreReport')}>
                        <FaHeadphonesAlt color='inherit'/>
                        <Box width={'4px'}></Box>
                        {t('score_report')}
                    </Tab>
                    {sections.map((sectionObj, index) => {
                        let icon;
                        if (sectionObj === 'listening') {
                            icon = <FaHeadphonesAlt color='inherit'/>;
                        } else if (sectionObj === 'reading') {
                            icon = <FaReadme color='inherit'/>;
                        } else if (sectionObj === 'speaking') {
                            icon = <FiMic color='inherit'/>;
                        } else if (sectionObj === 'writing') {
                            icon = <FiFileText color='inherit'/>;
                        }
                        return (
                            <Tab key={index} onClick={() => setCurSection(sectionObj)}>
                                {icon} <Box width={'4px'}></Box> {t(sectionObj)}
                            </Tab>
                        );
                    })}
                </TabList>
            </Tabs>
            <Divider borderWidth={1}/>
            {curSection.includes('scoreReport') && <ToeflScoreReport sections={sections}/>}
            {curSection.includes('reading') && <ToeflReadingAnswer/>}
            {curSection.includes('listening') && <ToeflListeningAnswer/>}
            {curSection.includes('speaking') && <ToeflSpeakingAnswer/>}
            {curSection.includes('writing') && <ToeflWritingAnswer/>}
        </Box>
    );
}

export default ToeflAnswer;
