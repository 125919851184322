import {Box, Divider, Heading, Icon, Text, VStack} from '@chakra-ui/react';
import {FaHeadphones} from 'react-icons/fa';
import React, {useEffect, useRef} from "react";
import {ToeflIbtContext} from "./ToeflIbtContext";
import {useHistory} from "react-router-dom";

function ToeflListeningDirections() {
    const directionsAudio = "https://lingoleapstorage1.blob.core.windows.net/sm-audio/ListeningSectionDirections.mp3";
    const audioRef = useRef(null); // 创建 ref
    const {volume} = React.useContext(ToeflIbtContext);
    const history = useHistory();
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume; // 设置音量
        }
    }, [volume]);

    const handleAudioEnded = () => {
        const parentPath = location.pathname.split("/directions")[0]
        const nextUrl = `${parentPath}/audio/1`;
        history.push(nextUrl);
    };


    return (
        <Box maxWidth="860px" margin="auto" padding="4">
            <VStack spacing={8} align="stretch">
                <audio
                    src={directionsAudio}
                    autoPlay={true} // 自动播放音频
                    ref={audioRef} // 使用 ref 来控制音频
                    onEnded={handleAudioEnded}
                    style={{display: 'none'}} // 隐藏音频控件
                />
                <Heading as="h1" size="lg" textAlign="left" style={{fontWeight: 'normal'}}>
                    Listening Section Directions
                </Heading>
                <Divider borderWidth={1}/>
                <Text>
                    In this section, you will demonstrate your ability to understand conversations and lectures in
                    English. You will hear each conversation or lecture only <span
                    style={{fontWeight: 'bold'}}>one</span> time.
                </Text>
                <Text>
                    In some questions, you will see a headphone icon:
                    <Icon as={FaHeadphones} color={"teal.500"}
                          fontSize={20}
                          style={{verticalAlign: '-2px'}}/> This
                    means that you will
                    hear, but not see, part of the question.
                </Text>
                <Text>
                    You must answer each question. After you answer, select <span
                    style={{fontWeight: 'bold'}}>Next</span>.
                    You cannot return to the previous
                    questions.
                </Text>
                <Text>
                    Select <span style={{fontWeight: 'bold'}}>Continue</span> to go on.
                </Text>
            </VStack>
        </Box>
    );
}

export default ToeflListeningDirections;
