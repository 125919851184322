import React, {useState} from 'react';
import {Box, Spinner, Tab, TabList, Tabs, Text} from '@chakra-ui/react';
import {ViewResultPanel} from "../ViewResultPanel";
import {ToeflIbtContext} from "./ToeflIbtContext";

function ToeflWritingAnswer() {
    const {questionContent, answerContent} = React.useContext(ToeflIbtContext);
    const [gid, setGid] = useState(null);

    return (
        <Box margin="auto" padding="1" bg={'gray.100'} height={"92vh"} width={'100%'}>
            <Tabs variant='enclosed' bg={'white'} borderRadius={'md'}>
                <TabList>
                    {questionContent?.['writing']?.tasks.map((task, index) => (
                        <Tab key={index} onClick={() => {
                            const answer = answerContent?.['writing']?.[index + 1];
                            console.log(answer, 'answer')
                            if (answer && answer.startsWith('G_')) {
                                setGid(answer);
                            } else {
                                setGid(null)
                            }
                        }}>
                            Part {index + 1}
                        </Tab>
                    ))}
                </TabList>
            </Tabs>
            {gid ? <ViewResultPanel gid={gid}></ViewResultPanel> : <Box>
                <Spinner color="blue.500" borderWidth="4px" size="lg"/>
                <Text>
                    t("under_review")
                </Text>
            </Box>}
        </Box>
    );
}

export default ToeflWritingAnswer;
