import React, {useEffect} from "react";
import {selectToeflData, updateSection, updateTabIndex} from "../redux/toeflSlice";
import {FiBookmark, FiBookOpen} from "react-icons/fi";
import {FaPen} from "react-icons/fa";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from 'react-i18next';
import {mapToeflTabIndex} from "./utils/common";
import QuestionListPanel from "./QuestionListPanel";
import ToeflCustomQuestionPanel from "./ToeflCustomQuestionPanel";
import {RightPanel} from "./RightPanel";

export function ToeflRightPanel(props) {
    const toeflData = useSelector(selectToeflData);
    const tabIndex = toeflData.tabIndex;
    const url = new URL(window.location.href);
    const section = url.pathname.split('/').find(part => ['reading', 'listening', 'writing', 'speaking','full_mock_test'].includes(part));
    // console.log("section", section)
    const {t, i18n} = useTranslation();
    var tabList = [
        {
            "icon": FiBookOpen,
            "display": true,
            "title": "TPO",
            "showName": "TPO",
            "component": <QuestionListPanel test='toefl' section={section} questionSet={"tpo"}></QuestionListPanel>
        },
        {
            "icon": FiBookmark,
            "display": section !=='full_mock_test',
            "title": "QuestionBank",
            "showName": "question_bank",
            "component": <QuestionListPanel test='toefl' section={section} questionSet={"questionBank"}/>
        },
        {
            "icon": FaPen,
            "display": ['writing', 'speaking'].includes(section),
            "title": "CustomQuestion",
            "showName": "customized_topic",
            "component": <ToeflCustomQuestionPanel></ToeflCustomQuestionPanel>
        }]

    const dispatch = useDispatch();
    useEffect(() => {
        if (window.location.pathname.includes('writing') && toeflData.section !== 'writing') {
            dispatch(updateSection('writing'))
        } else if (window.location.pathname.includes('speaking') && toeflData.section !== 'speaking') {
            dispatch(updateSection('speaking'))
        } else if (window.location.pathname.includes('reading') && toeflData.section !== 'reading') {
            dispatch(updateSection('reading'))
        } else if (window.location.pathname.includes('listening') && toeflData.section !== 'listening') {
            dispatch(updateSection('listening'))
        }
    }, [])

    return <>
        <RightPanel tabIndex={tabIndex} updateTabIndexfunc={updateTabIndex} mapTabIndexfunc={mapToeflTabIndex}
                    tabList={tabList}/>
    </>
}