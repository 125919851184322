import {Box, Divider, Flex, Heading, Icon, Text, VStack} from '@chakra-ui/react';
import React from 'react';
import {FaCheck, FaTimes} from 'react-icons/fa';

function ToeflAdjustingTheMicrophone() {

    const ShownIndicatorPart = ({value,color}) => {
        return (
            <Flex justify="space-between" mb={2}>
                {[...Array(6)].map((_, i) => (
                    <Box
                        key={i}
                        w={4}
                        h={10}
                        bg={i < value ? color : "gray.300"}
                        mr={1}
                        borderRadius="2px"
                    />
                ))}
            </Flex>
        );
    };

    const ShownIndicator = ({value,color}) => {
        return (
            <Flex>
                <Flex direction={"column"} alignItems="center" mt={4}>
                    <ShownIndicatorPart value={value} color={color}></ShownIndicatorPart>
                    <Text>Too Quiet</Text>
                </Flex>
                <Divider orientation="vertical" borderColor="black" borderStyle="dashed" borderWidth={1} height="75px"
                         mr={1}/>
                <Flex direction={"column"} alignItems="center" mt={4}>
                    <ShownIndicatorPart value={value - 6} color={color}></ShownIndicatorPart>
                    <Text>Good</Text>
                </Flex>
                <Divider orientation="vertical" borderColor="black" borderStyle="dashed" borderWidth={1} height="75px"
                         mr={1}/>
                <Flex direction={"column"} alignItems="center" mt={4}>
                    <ShownIndicatorPart value={value - 12} color={color}></ShownIndicatorPart>
                    <Text>Too Loud</Text>
                </Flex>
            </Flex>
        );
    }

    return (
        <Box maxWidth="860px" margin="auto" padding="4">
            <VStack spacing={8} align="stretch">
                <Heading as="h1" size="lg" textAlign="left" style={{fontWeight: 'normal'}}>
                    Adjusting the Microphone
                </Heading>
                <Divider borderWidth={1}/>
                <Text mt="4">
                    In order to check your microphone volume, you will speak into the microphone using your normal tone
                    and volume.
                    For best recording results, your voice level should remain generally within the Good Range. While
                    you speak,
                    the microphone will adjust automatically.
                </Text>
                <Text>Example:</Text>
                <Flex>
                    <Flex direction={"column"} justifyContent="space-between" width="100%" alignItems="center">
                        <ShownIndicator value={9} color={"teal.500"}></ShownIndicator>
                        <Flex alignItems="center" mt={4}>
                            <Icon as={FaCheck} color="teal.500" fontSize={50} style={{verticalAlign: '-2px'}} mr={4}/>
                            <Text fontSize={"xl"} style={{fontWeight: 'bold'}} >Good</Text>
                        </Flex>
                    </Flex>
                    <Flex direction={"column"} justifyContent="space-between" width="100%" alignItems="center">
                        <ShownIndicator value={17} color={"red.300"} ></ShownIndicator>
                        <Flex alignItems="center" mt={4}>
                            <Icon as={FaTimes} color="red.300" fontSize={50} style={{verticalAlign: '-2px'}} mr={4}/>
                            <Text  fontSize={"xl"} style={{fontWeight: 'bold'}} >Too Loud</Text>
                        </Flex>
                    </Flex>
                </Flex>
            </VStack>
        </Box>
    );
}

export default ToeflAdjustingTheMicrophone;
