import {Box, Button, Flex, HStack, Image, Text, Textarea, VStack} from '@chakra-ui/react';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import {ToeflIbtContext} from "./ToeflIbtContext";
import {debounce} from "lodash";

function ToeflWritingQuestion({setIsCountdownPaused}) {
    const {partIdx} = useParams();
    const {
        questionContent,
        setAnswerContent
    } = React.useContext(ToeflIbtContext)
    const [question, setQuestion] = useState({});
    const audioRef = useRef(null);
    const [display, setDisplay] = useState(false);
    const textRef = useRef();
    const [inputValue, setInputValue] = useState('');
    const [wordCount, setWordCount] = useState(0);
    const [historyStack, setHistoryStack] = useState([]);
    const [futureStack, setFutureStack] = useState([]);
    setIsCountdownPaused(false) // todo 当前写作题目音频缺失，自动开始计时
    const [readingTexts, setReadingTexts] = useState([]);

    useEffect(() => {
        // console.log(questionContent, 'questionContent`')
        const taskObj = questionContent?.['writing']?.tasks[partIdx - 1];
        if (taskObj) {
            setQuestion(taskObj);
            const key = `toefl_answer_${taskObj?.qid}`;
            handleChange(localStorage.getItem(key) || '')
        }
        const texts = questionContent?.['writing']?.tasks[partIdx - 1].reading_texts;
        let flattenedTexts = []
        if (typeof texts === 'string') {
            flattenedTexts = texts.split('\n')
        } else if (Array.isArray(texts)) {
            flattenedTexts = texts?.flatMap(text => text.split('\n'));
        }
        setReadingTexts(flattenedTexts)
    }, [questionContent, partIdx]);


    const handleCut = () => {
        document.execCommand('cut');
        const currentState = textRef.current.value;
        setHistoryStack([...historyStack, currentState]);
        setFutureStack([]);
        handleChange(currentState);
    }

    const handlePaste = async () => {
        const text = await navigator.clipboard.readText();
        const currentState = textRef.current.value + text;
        setHistoryStack([...historyStack, currentState]);
        setFutureStack([]);
        handleChange(currentState);
    }

    const handleUndo = async () => {
        if (historyStack.length > 0) {
            const currentState = historyStack[historyStack.length - 1];
            const newState = historyStack.slice(0, historyStack.length - 1);
            setHistoryStack(newState);
            setFutureStack([currentState, ...futureStack]);
            handleChange(currentState);
        }
    }

    const handleRedo = async () => {
        if (futureStack.length > 0) {
            const currentState = futureStack[0];
            const newState = futureStack.slice(1);
            setFutureStack(newState);
            setHistoryStack([...historyStack, currentState]);
            handleChange(currentState);
        }
    }


    const handleInputChange = (e) => {
        const currentState = e.target.value;
        console.log("Input changed:", currentState)
        setHistoryStack([...historyStack, currentState]);
        setFutureStack([]);
        handleChange(currentState);
    }


    function handleChange(currentState) {
        const wordCount = currentState === '' ? 0 : currentState.split(/\s+/).length;
        setInputValue(currentState);
        setWordCount(wordCount);
        debouncedSaveAnswer(currentState);
    }

    const debouncedSaveAnswer = useCallback(
        debounce((answer) => {
            const key = `toefl_answer_${question?.qid}`;
            localStorage.setItem(key, answer);
            console.log("Saved answer:", answer);
            setAnswerContent(prevAnswerContent => ({
                ...prevAnswerContent,
                writing: {
                    ...prevAnswerContent.writing,
                    [partIdx]: answer
                }
            }));
        }, 2000),
        [question?.qid]
    );


    return (
        <Box maxWidth="70%" margin="auto" padding="4" height={"100%"}>
            {(partIdx === "1") && (<VStack spacing={8} align="stretch">
                <Box borderRadius={"xl"} width={"100%"} border={"1px solid gray"} overflow={"hidden"}>
                    <audio
                        src={question?.question_audio}
                        autoPlay={true} // 自动播放音频
                        ref={audioRef}
                        onEnded={() => {
                            setTimeout(() => {
                                setDisplay(true);
                                setIsCountdownPaused(false)
                            }, 500);
                        }}
                        style={{display: 'none'}} // 隐藏音频控件
                    />
                    <Flex direction={"column"} align={'center'}>
                        <Box bg='blue.50' border='1px' w='100%' p={4}>
                            <Text><Text as='b'>Directions: </Text>You have
                                20 minutes to plan and write your response. Your response will be judged on the
                                basis of
                                the quality of your writing and on how well you response presents the points in the
                                leture and their relationship to the reading passage. Typically, an effective
                                response
                                will be 150 to 225 words.</Text>
                        </Box>
                        <Box border='1px' w='100%' p={4}>
                            <Text><Text as='b'>Question: </Text>{question?.question}</Text>
                        </Box>
                    </Flex>
                </Box>
            </VStack>)}
            <VStack alignItems={'left'} height={partIdx === "1" ? '88%' : '100%'}>
                <HStack pt={4} w={'100%'} alignItems="flex-start" height={'100%'}>
                    <Box w={'50%'} h={'100%'}>
                        <VStack
                            border="2px"
                            borderColor="gray.200"
                            borderRadius="16"
                            height="100%"
                            width="100%"
                            spacing={0}
                        >
                            <Box
                                overflowY="auto"
                                width="100%"
                                height='94%'
                            >
                                <VStack spacing={4} align="stretch" p={3}>
                                    {readingTexts && readingTexts.map((text, id) => {
                                        return <div key={id}
                                                    dangerouslySetInnerHTML={{__html: text}}
                                        />
                                    })}
                                    {(partIdx === '2') && (
                                        <Box p={4}>
                                            <Text fontWeight={'bold'} mb={2}>Instructions</Text>
                                            <div dangerouslySetInnerHTML={{__html: question?.question}}/>
                                            <Flex direction={"column"} alignItems={'center'} mt={4}>
                                                {question?.discussion1_img && <Image
                                                    src={question?.discussion1_img}
                                                    alt="image"
                                                    width="120px"
                                                    objectFit="cover"
                                                />}
                                                <Text fontWeight={'bold'}
                                                      alignItems={'center'} mt={2}>{question?.discussion1_name}</Text>
                                            </Flex>
                                            <Text mt={4}>{question?.discussion1_content}</Text>
                                        </Box>
                                    )}
                                </VStack>
                            </Box>
                        </VStack>
                    </Box>
                    <Box w={'50%'} h={'100%'}>
                        <Flex direction="column" h="100%">

                            {(partIdx === '2') && (
                                <VStack
                                    // border="2px"
                                    borderColor="gray.200"
                                    borderRadius="16"
                                    spacing={0}
                                    p={4}
                                    mb={2}
                                >
                                    <Flex direction={"row"} alignItems={'center'}>
                                        <VStack alignItems={'center'} mt={4} width={'300px'} mr={2}>
                                            {question?.discussion2_img && <Image
                                                src={question?.discussion2_img}
                                                alt="image"
                                                width="80px"
                                                objectFit="cover"
                                            />}
                                            <Text fontWeight={'bold'} alignItems={'center'} mt={2}
                                            >{question?.discussion2_name}</Text>
                                        </VStack>
                                        <Text mt={4}>{question?.discussion2_content}</Text>
                                    </Flex>
                                    <Flex direction={"row"} alignItems={'center'}>
                                        <VStack alignItems={'center'} mt={4} width={'300px'} mr={2}>
                                            {question?.discussion3_img && <Image
                                                src={question?.discussion3_img}
                                                alt="image"
                                                width="80px"
                                                objectFit="cover"
                                            />}
                                            <Text fontWeight={'bold'} alignItems={'center'} mt={2}
                                            >{question?.discussion3_name}</Text>
                                        </VStack>
                                        <Text mt={4}>{question?.discussion3_content}</Text>
                                    </Flex>
                                </VStack>
                            )}
                            <Flex direction={"row"} justify="space-between">
                                <Flex>
                                    <Button borderWidth="1px" onClick={handleCut} mr={2}>Cut</Button>
                                    <Button borderWidth="1px" onClick={handlePaste} mr={2}>Paste</Button>
                                    <Button borderWidth="1px" onClick={handleUndo} mr={2}>Undo</Button>
                                    <Button borderWidth="1px" onClick={handleRedo} mr={2}>Redo</Button>
                                </Flex>
                                <Flex>
                                    <Text mt={2}>Word Count: {wordCount}</Text>
                                </Flex>
                            </Flex>
                            <Textarea onPaste={handlePaste} ref={textRef} value={inputValue}
                                      onChange={handleInputChange}
                                      placeholder="Enter your answer here..." size="sm"
                                      spellCheck="false"
                                      borderRadius="16"
                                      mt={2}
                                      flex={1}
                            />
                        </Flex>
                    </Box>
                </HStack>
            </VStack>
        </Box>
    );
}

export default ToeflWritingQuestion;
