import {Box, Divider, Heading, Text, VStack} from '@chakra-ui/react';
import React, {useEffect, useRef} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {ToeflIbtContext} from "./ToeflIbtContext";

function ToeflWritingDirections() {
    const {partIdx} = useParams();
    let history = useHistory();
    const {
        volume
    } = React.useContext(ToeflIbtContext);
    let directionAudio = ''
    if (partIdx === undefined) {
        directionAudio = '' // todo 缺失
    } else if (partIdx === '1') {
        directionAudio = 'https://lingoleapstorage1.blob.core.windows.net/sm-audio/writing_section_directions1.mp3'
    } else if (partIdx === '2') {
        directionAudio = 'https://lingoleapstorage1.blob.core.windows.net/sm-audio/writing_section_directions2.mp3'
    }
    const audioRef = useRef(null);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume; // 设置音量
        }
    }, [volume]);


    function getNextUrl() {
        const curPath = location.pathname
        if (partIdx === undefined) {
            return `${curPath}/1`;
        } else if (partIdx === '1') {
            return curPath.replace('/directions', '/text');
        } else if (partIdx === '2') {
            return curPath.replace('/directions', '/question');
        }
    }

    const handleAudioEnded = () => {
        const nextUrl = getNextUrl();
        history.push(nextUrl);
    };


    return (
        <Box maxWidth="860px" margin="auto" padding="4">
            <audio
                src={directionAudio}
                autoPlay={true} // 自动播放音频
                ref={audioRef}
                onEnded={() => {
                    handleAudioEnded();
                }}
                style={{display: 'none'}} // 隐藏音频控件
            />
            {partIdx === undefined && (
                <VStack spacing={8} align="stretch">
                    <Heading as="h1" size="lg" textAlign="left" style={{fontWeight: 'normal'}}>
                        Writing Section Directions
                    </Heading>
                    <Divider borderWidth={1}/>
                    <Text>
                        In this section, you will be able to demonstrate your ability to understand academic passages in
                        English. You will read and answer questions about <span style={{fontWeight: 'bold'}}>two
                        passages</span>. A clock will indicate how much time remains. In the actual test, you will
                        have <span
                        style={{fontWeight: 'bold'}}>36 minutes</span> to
                        read the two passages and answer the questions.
                    </Text>
                    <Text>
                        Some passages include an underlined word or phrase. Select the word or phrase to see a
                        definition, an explanation, or an illustration.
                    </Text>
                    <Text>
                        Move to the next question by selecting <span style={{fontWeight: 'bold'}}>Next</span>. You can
                        skip questions and go back to them later if
                        time remains. Return to previous screens by selecting <span
                        style={{fontWeight: 'bold'}}>Back</span>.
                    </Text>
                    <Text>
                        Select <span style={{fontWeight: 'bold'}}>Review</span> at any time to see which questions you
                        have answered and which questions you have
                        not answered. From the Review screen, you may go directly to any question you have already
                        encountered.
                    </Text>
                    <Text>
                        Select <span style={{fontWeight: 'bold'}}>Begin</span> to go on.
                    </Text>
                </VStack>
            )}
            {partIdx === '1' && (
                <VStack spacing={8} align="stretch">
                    <Heading as="h1" size="lg" textAlign="left" style={{fontWeight: 'normal'}}>
                        Writing Based on Reading and Listening Directions
                    </Heading>
                    <Divider borderWidth={1}/>
                    <Text>
                        For this task, you will have 3 minutes to read a passage about an academic topic. Then you will
                        listen to a lecture about the same topic.
                    </Text>
                    <Text>
                        You will have 20 minutes to write. In your response, provide a detailed summary of the lecture
                        and explain how the lecture relates to the reading passage. While you write, you will be able to
                        see the reading passage.
                    </Text>
                    <Text>
                        In <span style={{fontWeight: 'bold'}}>Untimed Mode</span> in this practice test, you may listen
                        to
                        the lecture again by selecting <span style={{fontWeight: 'bold'}}>Replay Talk</span>. This
                        function is not available in the actual test. Note that returning to the lecture could result in
                        a section score higher than the score you would receive if encountering the lecture only one
                        time.
                    </Text>
                    <Text>
                        If you finish your response before time is up, you may select Next to go on to the second
                        writing task.
                    </Text>
                    <Text>
                        Now you will see the reading passage. It will be followed by a lecture.
                    </Text>
                    <Text>
                        (Select Begin at any time to dismiss these directions.)
                    </Text>
                </VStack>

            )}
            {partIdx === '2' && (
                <VStack spacing={8} align="stretch">
                    <Heading as="h1" size="lg" textAlign="left" style={{fontWeight: 'normal'}}>
                        Writing for an Academic Discussion Directions
                    </Heading>
                    <Divider borderWidth={1}/>
                    <Text>
                        For this task, you will read an online discussion. A professor has posted a question about a
                        topic, and some classmates have responded with their ideas.
                    </Text>
                    <Text>
                        You will write a response that contributes to the discussion. In the actual test and in Timed
                        Mode in this practice test, you will have 10 minutes to write your response.
                    </Text>
                    <Text>
                        If you finish your response before time is up, you may select Next to end this section.
                    </Text>
                    <Text>
                        (Select Begin at any time to dismiss these directions.)
                    </Text>
                </VStack>
            )}
            {partIdx === 'warning' && (
                <VStack spacing={8} align="stretch">
                    <Heading as="h1" size="lg" textAlign="left" style={{fontWeight: 'normal'}}>
                        Time Remaining for Writing Response
                    </Heading>
                    <Divider borderWidth={1}/>
                    <Text>
                        You still have time to write your response. As long as there is time remaining,
                        you can keep writing or revising your response.
                    </Text>
                    <Text>
                        Select <span style={{fontWeight: 'bold'}}>Back</span> to keep writing or revising Select <span
                        style={{fontWeight: 'bold'}}>Continue</span> to leave this question.
                    </Text>
                    <Text>
                        Once you leave this question you will not be able to return to it.
                    </Text>
                </VStack>
            )}
        </Box>
    );
}

export default ToeflWritingDirections;
