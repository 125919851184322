// Chakra imports
import {
    Flex, Grid, SimpleGrid,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import { useUpdateUserPreferencesMutation } from "services/cmApi";
import { useSelector } from "react-redux";
// Custom components
import { Button } from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import React, { useEffect, useState } from "react";
import { ToeflRightPanel } from "../../components/ToeflRightPanel";
import { QuickLinksPanel } from "../../components/QuickLinksPanel";
import AuthService from "../../services/auth.service";
import { useHistory } from "react-router-dom";
import UpdatesModal from "../../components/UpdatesModal";
import { selectIeltsData } from "redux/ieltsSlice";
import { selectToeflData } from "redux/toeflSlice";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import update from "react-addons-update";
import { useDispatch } from "react-redux";
import { updateSelection, updateSelectedProduct } from "redux/toeflSlice";
function ToeflView() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    let history = useHistory();
    const dispatch = useDispatch();

    const [updateUserPreferencesRequest, isUpdateUserPreferencesError] = useUpdateUserPreferencesMutation();

    useEffect(() => {
        if (!AuthService.getCurrentUser()) {
            history.push("/login");
        }
    }, []);  // <-- empty dependency array to run only once on mount

    const location = window.location.pathname;
    const [modal, setModal] = useState(false);
    const [inputProduct, setInputProduct] = useState("toefl");
    const ieltsData = useSelector(selectIeltsData);
    const toeflData = useSelector(selectToeflData);

    useEffect(() => {
        if (location === "/toefl/speaking" && toeflData.isSelectionDone === false && toeflData.selectedProduct === "") {
            setModal(true);
        }

    }, [!toeflData.isSelectionDone])


    console.log(toeflData.isSelectionDone, "toefl")

    async function updatePreference() {
        let prefs = {};
        prefs['product'] = inputProduct;
        try {
            const response = await updateUserPreferencesRequest(prefs);
            if (response) {
                let section = 'speaking';
                if (inputProduct === 'toefl') {
                    dispatch(updateSelectedProduct(inputProduct));
                    section = toeflData.section;
                } else if (inputProduct === 'ielts') {
                    dispatch(updateSelectedProduct(inputProduct));
                    section = ieltsData.section;
                }
                history.push(`/${inputProduct}/${section}`);
                dispatch(updateSelection(true));
            } else {
                console.log(response, "response");
            }
        } catch (error) {
            console.error('Error updating preferences:', error);
        }
    }

    const onClose = () => {
        setModal(false);
    };

    console.log(location)
    console.log(modal, "modal")
    console.log(toeflData.selectedProduct, "productToefl")

    console.log(toeflData.isSelectionDone, "selection")
    console.log(inputProduct, "product")
    return (
        <Flex flexDirection='column' pt="80px">
            <Grid
                templateColumns={{ sm: "1fr", lg: "4fr 1fr" }}
                templateRows={{ lg: "repeat(2, auto)" }}
                gap='20px'>
                <Card overflowX={{ sm: "scroll", xl: "hidden" }} width="100%" p={{ base: "0px", xs: "22px" }} py="22px"
                >
                    {/* <Button onClick={onOpen}>Open Modal</Button> */}
                    <Modal isOpen={modal && !toeflData.isSelectionDone} onClose={onClose} placement='middle'>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader textAlign="center" bgColor="yellow.400">Exam Overview</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                Are you preparing for TOEFL or IELTS?
                            </ModalBody>
                            <ModalBody display="flex" gap="10px">
                                <Button value={"toefl"} onClick={(e) => { setInputProduct(e.target.value) }}>TOEFL</Button>
                                <Button value={"ielts"} onClick={(e) => { setInputProduct(e.target.value) }}>IELTS</Button>
                            </ModalBody>

                            <ModalFooter>
                                <Button colorScheme='blue' mr={3} onClick={() => setModal(!modal)}>
                                    Close
                                </Button>
                                <Button variant='ghost' onClick={updatePreference}>Submit</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                    <ToeflRightPanel></ToeflRightPanel>
                </Card>
                <QuickLinksPanel></QuickLinksPanel>
            </Grid>
            <UpdatesModal></UpdatesModal>
        </Flex>
    );
}

export default ToeflView;
